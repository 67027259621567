
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"
import Img from "gatsby-image"

// not sure how to include the sw
// import * as serviceWorker from '../serviceWorker';
import '../index.css';
import '../App.css';

const importBlock = () => import("react-particles-js").then(
  component => component.default
)

// React.lazy(() => import("react-particles-js"));

function Menu() {
  return (
    <div className="menu"> 
      <div>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.welcomeSection').scrollIntoView({behavior:'smooth'})}}>
          Home
        </a>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.angebotSection').scrollIntoView({behavior:'smooth'})}}>
          Services
        </a>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.teamSection').scrollIntoView({behavior:'smooth'})}}>
          Contact
        </a>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.referencesSection').scrollIntoView({behavior:'smooth'})}}>
          References
        </a>
      </div>
    </div>
  );
}

function WelcomeSection({data, component}) {
  // particlesJS.load('particles-js', 'assets/particles.json', function() {
  //   console.log('callback - particles.js config loaded');
  // });

  return (
      <header className="welcomeSection">
        {component.length > 0 ? component.map(Particles => (
          <Particles 
            key="particles"
            style={{
              position: 'absolute', 
              width: '100vw', 
              height: '100vh', 
              top: 0, 
              left: 0, 
              zIndex: -1,
              backgroundColor: '#181C1E',
            }} 
            params={{particles: {number: {value: 300}, line_linked: {enable: false}}}}
            /> 
        )) : 
          <div 
            key="placeholder"
            style={{
            position: 'absolute', 
            width: '100vw', 
            height: '100vh', 
            top: 0, 
            left: 0, 
            zIndex: -1,
            backgroundColor: '#181C1E',
          }} />
        }
        {/*<div className="logo">
          <Img 
            style={{ margin: '1rem', maxHeight: 'calc(50vh - 4rem)' }}
            key={data.Logo.childImageSharp.fluid.src}
            imgStyle={{ objectFit: 'contain' }}
            fluid={data.Logo.childImageSharp.fluid} 
            alt="logo" 
            />
        </div>*/}
        <p> Apps, websites und artificial intelligence.</p>
        <div style={{height: '10vh'}}> </div>
      </header>
  );
}

function AngebotSection () {
  return (
    <section className="angebotSection">
      <h1>
        What we offer
      </h1>
      <p>
        The opportunities are unlimited.
      </p>
      <div style={{textAlign: 'center'}}>
        <div style={{maxWidth: 700, margin: '0 auto'}}>
          <p>
            Smart solutions are our DNA at Codematch Limited. 
            Our goal is to find pragmatic solutions for any IT problem.
            For this we develop apps, websites, database systems and AI applications.
          </p>
        </div>
      </div>

    </section>
  );
}

function ReferencesSection() {
  return (
    <section className="referencesSection">
      <h1>References</h1>
      <div style={{textAlign: 'left'}}>
        <div style={{maxWidth: 900, margin: '0 auto'}}>
          <ul>
            <li>
              <a className="blackLink" target="_blank" href="https://covidtracker.ch">covidtracker.ch</a> <br />
              In collaboration with the Swiss canton of Bern - a platform to track the spread of the coronavirus.
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://huggenbergermonitor.com">huggenbergermonitor.com</a> <br />
              A platform for construction projects with live-monitoring and SMS-notifications.
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://lucnat.github.io/freedive">Freedive App</a> <br />
              An app to practice holding your breath (for free divers) iOS and Android. 
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://posture-penguin.web.app/">Posture Penguin</a> <br />
              A prototype AI application that monitors body posture while working.
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://brigitaklin.ch/">Brigit Aklin | Schmuck</a> <br />
              The personal website of Brigit Aklin, goldsmith in Zurich.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

function MapSection() {
  return (
    <iframe 
      style={{height: '400px', width: '100%'}} 
      id="gmap_canvas" 
      src="https://maps.google.com/maps?q=Shelton+St,+London,+UK%20&t=&z=13&ie=UTF8&iwloc=&output=embed" 
      frameBorder="0" 
      scrolling="no" 
      marginHeight="0" 
      marginWidth="0"></iframe>
  );
}

function TeamSection() {
  return (
    <section className="teamSection">
      <h1>Team</h1>
      <div style={{textAlign: 'left'}}>
        <div style={{maxWidth: 800, margin: '0 auto'}}>
          <p style={{marginTop: 40}}>
            <b>Jonathan Lehner</b>, <i>Allrounder</i> 
            <br /><a href="mailto:jonathan@codematch.jonathanlehner.com">jonathan@codematch.jonathanlehner.com</a> <br /><br />
            Jonathan studied Computer Science at ETH Zurich and taught himself programming websites and apps in his free time. 
            If there there are very difficult problems - for example in AI - then his education is very helpful.
          </p>
        </div>
      </div>
      <h1>Kontakt</h1>
        <div style={{maxWidth: 800, margin: '0 auto'}}>
          <p>Codematch Limited, 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, UNITED KINGDOM</p>
          <a href="mailto:jonathan@codematch.jonathanlehner.com">jonathan@codematch.jonathanlehner.com</a>
        </div>
        <br />
    </section>
  );
}

function FooterSection({component}) {
  return (
    <div className="footerSection" style={{position: 'relative', display: 'flex', flexDirection: 'column', alignItem: 'center'}}>
      {component.length > 0 ? component.map(Particles => (
          <Particles 
            key="particles"
            style={{
              position: 'absolute', 
              width: '100vw', 
              height: '100vh', 
              top: 0, 
              left: 0, 
              zIndex: -1,
              backgroundColor: '#181C1E',
            }} 
            params={{particles: {number: {value: 30}, line_linked: {enable: false}}}}
            /> 
        )) : 
          <div 
            key="placeholder"
            style={{
            position: 'absolute', 
            width: '100vw', 
            height: '100vh', 
            top: 0, 
            left: 0, 
            zIndex: -1,
            backgroundColor: '#181C1E',
          }} />
        }
      <p> © 2020 by Codematch Limited</p>
    </div>
  );
}

function App({data}) {

  const [component, setComponent] = useState([])
  useEffect(() => {
    async function loadComponents() {
        const component = await importBlock();
        setComponent(loadedComponents => loadedComponents.concat(component))
    }
    window.setTimeout(loadComponents, 2500);
  }, [])

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Milliways Zürich | Apps, Websites and Artificial Intelligence"
        />
        <title>Codematch | London</title>
        <link 
          rel="preload"
          href={"https://fonts.googleapis.com/css?family=Quicksand&display=swap"} 
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"      
          />
      </Helmet>
      <div className="App">
        <Menu />
        <WelcomeSection data={data} component={component} />
        <AngebotSection />
        <TeamSection />
        <MapSection />
        <ReferencesSection />
        <FooterSection component={component} />
      </div>
    </>
  );
}

export default App;

// see this for explanation how to use lazy loaded images:
// https://www.gatsbyjs.com/plugins/gatsby-image/
export const query = graphql`
  query {
    Logo: file(relativePath: { eq: "logo-transparent.png" }) {
      childImageSharp {
        fluid(maxWidth: 336, maxHeight: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;